import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { components as SelectComponents } from "react-select"

import ArrowDown from "static/icons/theme/arrow-down.svg"

export const DropdownWrapper = styled.div`
  ${tw`hidden md:block`}
  ${props => (props.disabled ? tw`pointer-events-none opacity-50` : ``)}
`
export const Icon = styled(ArrowDown)`
  ${tw`absolute right-0 block w-0-8 h-0-8 pointer-events-none`}
  ${props => (props.dropdown ? tw`mr-1-6 -mt-0-4` : tw`-mt-0-1`)}
  ${props => (props.nativeSelect ? tw`mr-1-6` : ``)}
`
export const Text = tw.div`font-body text-sm text-dark`
export const AdditionalButtons = tw.div`block w-full mb-1-2`
export const Button = tw.button`block w-full no-underline text-dark font-body text-sm text-left hover:underline mt-0-2 py-0-4 px-1-6 bg-transparent focus:outline-none leading-normal`
export const Placeholder = tw.div`inline-block text-dark mr-1`
export const Value = tw.div`inline-block text-grey-darker`
export const OptionWrapper = styled.div`
  ${tw`flex items-center justify-between`}
  ${props => (props.first ? tw`border-t border-grey pt-1` : ``)}
`
export const OptionValue = styled.div`
  ${props => (props.half ? tw`w-1/2` : tw`w-full text-left`)}
  ${props => (props.disabled ? tw`text-grey-darker` : ``)}
`
export const OptionButton = styled(Button)`
  ${tw`text-right pl-0 pr-1`}
  ${props => (props.onClick ? tw`underline` : tw`text-grey-darker pointer-events-none`)}
`

export const DropdownIndicator = props => (
  <SelectComponents.DropdownIndicator {...props}>
    <Icon dropdown />
  </SelectComponents.DropdownIndicator>
)

export const Control = props => (
  <Text>
    <SelectComponents.Control {...props} />
  </Text>
)

export const SingleValue = ({ children, selectProps }) => (
  <>
    {selectProps.placeholder ? <Placeholder>{selectProps.placeholder}</Placeholder> : null}
    <Value>{children}</Value>
  </>
)

export const Menu = props => (
  <Text>
    <SelectComponents.Menu {...props}>{props.children}</SelectComponents.Menu>
  </Text>
)

export const MenuList = props => (
  <>
    <SelectComponents.MenuList {...props}>{props.children}</SelectComponents.MenuList>
    {props.selectProps && props.selectProps.onLinkClick && props.selectProps.linkText ? (
      <AdditionalButtons>
        <Button onClick={props.selectProps.onLinkClick}>{props.selectProps.linkText}</Button>
      </AdditionalButtons>
    ) : null}
  </>
)

export const Option = props => (
  <OptionWrapper {...props.innerProps} first={!props.options.findIndex(opt => opt.value === props.data.value)}>
    <OptionValue disabled={props.data.disabled} half={!!props.data.button}>
      <SelectComponents.Option {...props} />
    </OptionValue>

    {props.data.button ? (
      <OptionValue half={!!props.data.button}>
        <OptionButton onClick={props.data.button.onClick}>{props.data.button.label}</OptionButton>
      </OptionValue>
    ) : null}
  </OptionWrapper>
)

export const Styles = {
  control: (provided, state) => ({
    cursor: "pointer",
    backgroundColour: "transparent",
    borderRadius: 0,
    border: "1px solid",
    borderColor: state.menuIsOpen ? "#000" : "#eee",
    display: "flex",
    flexWrap: "wrap",
    font: "inherit",
    justifyContent: "space-between",
    label: "control",
    outline: "0 !important",
    padding: "4px 16px",
    position: "relative",
    transition: "all 0.2s ease",
    "&:hover": {
      border: "1px solid #000",
      outline: "none",
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: "inherit",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: () => ({
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderTop: 0,
    boxSizing: "border-box",
    marginTop: "-1px",
    label: "menu",
    position: "absolute",
    top: "100%",
    width: "100%",
    zIndex: 1,
  }),
  menuList: provided => ({
    ...provided,
  }),
  option: (provided, state) => ({
    backgroundColor: "transparent",
    boxSizing: "border-box",
    color: "inherit",
    cursor: "pointer",
    display: "block",
    font: "inherit",
    label: "option",
    margin: "0 10px",
    padding: "6px",
    textDecoration: state.isSelected ? "underline" : "none",
    transition: "all 0.2s ease",
    userSelect: "none",
    "&:hover": {
      textDecoration: "underline",
      outline: "none",
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: "#000",
  }),
  singleValue: provided => ({
    ...provided,
    color: "inherit",
    font: "inherit",
  }),
  valueContainer: () => ({
    WebkitOverflowScrolling: "touch",
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    overflow: "hidden",
    padding: 0,
    position: "relative",
  }),
}
