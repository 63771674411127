import styled from "@emotion/styled"
import tw from "twin.macro"

import ArrowDown from "static/icons/theme/arrow-down.svg"
import ChevronDown from "static/icons/theme/chevron-down.svg"

export const Wrapper = styled.div`
  ${tw`relative items-center justify-between select-none transform transition-all duration-200`}
  ${props => (props.nativeSelect ? tw`flex md:hidden w-full border border-grey hover:border-dark py-0-8 px-1-6` : tw`flex`)}
  ${props => (props.translate ? tw`md:opacity-0 md:invisible md:-translate-y-3 md:pointer-events-none` : tw`opacity-100 visible translate-y-0`)}
`
export const Icon = tw(ChevronDown)`absolute right-0 block w-1-6 h-1-6 pointer-events-none -mt-0-1`
export const IconAlt = styled(ArrowDown)`
  ${tw`absolute right-0 block w-0-8 h-0-8 pointer-events-none`}
  ${props => (props.dropdown ? tw`mr-1-6 -mt-0-4` : tw`-mt-0-1`)}
  ${props => (props.nativeSelect ? tw`mr-1-6` : ``)}
`
export const Input = styled.select`
  ${tw`relative appearance-none pr-2 focus:outline-none text-xxs font-body font-medium text-inherit bg-transparent cursor-pointer`}
  ${props => (props.altIcon ? tw`text-xs` : tw`tracking-wider`)}
  ${props =>
    props.minimal
      ? tw`text-grey-darker text-right hover:text-grey-darkest font-body font-medium tracking-wider leading-normal uppercase text-xms md:px-1-2`
      : ``}
  ${props => (props.underline ? tw`underline` : tw``)}
  ${props => (props.nativeSelect ? tw`text-sm tracking-relaxed w-full` : ``)}
  ${props => (props.rightAligned ? tw`text-right tracking-wider` : ``)}
  ${props => (props.uppercase ? `text-transform: uppercase;` : ``)}
`
