import React from "react"
import Select from "react-select"

import { Select as NativeSelect } from "../Select/Select"
import { Control, DropdownIndicator, DropdownWrapper, Menu, MenuList, Option, SingleValue, Styles } from "./DropdownStyles"

interface Props {
  children: any
  disabled: boolean
  label: string
  linkText: string
  name: string
  options: any
  onChange: any
  onLinkClick: any
  selected: any
  selectProps: any
  value: string
  dir: string
}

export const Dropdown = ({ disabled, label, linkText, name, options, onLinkClick, onChange, selected, value, dir }: Props) => (
  <>
    <DropdownWrapper disabled={disabled}>
      <Select
        components={{ Control, DropdownIndicator, Menu, MenuList, Option, SingleValue }}
        defaultValue={selected ? options.find(option => option.value === selected) : null}
        options={options}
        onLinkClick={onLinkClick}
        linkText={linkText}
        name={name}
        onChange={onChange ? option => onChange(option.value, name) : null}
        placeholder={label}
        styles={Styles}
        value={options.find(option => option.value === value)}
      />
    </DropdownWrapper>

    <NativeSelect
      defaultValue={selected ? options.find(option => option.value === selected) : null}
      disabled={disabled}
      name={name}
      nativeSelect
      onChange={onChange ? event => onChange(event.target.value, name) : null}
      value={value || ``}
      dir={dir}
    >
      {label ? (
        <option disabled value={``}>
          {label}
        </option>
      ) : null}

      {options.map(({ label, miniLabel, value }, i) => (
        <option key={value || i} value={value}>
          {miniLabel || label}
        </option>
      ))}
    </NativeSelect>
  </>
)
